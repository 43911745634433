import React from 'react';
import { Container, LoadingDiv } from './styles';
import { IProspeccao, maskCPF, maskTelefone } from '../../../interfaces/index'
import ReactLoading from 'react-loading';
import { format, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

interface IProps {
  prospeccoes: IProspeccao[];
  listaFiltradaVazia?: boolean;
}

const TableProspeccao: React.FC<IProps> = ({ prospeccoes, listaFiltradaVazia = false }: IProps) => {


  return (
    <Container>

      <table>
        <tr>
          <th >Nome</th>
          <th >Telefone(s)</th>
          <th>Atividade</th>
          <th>CPF</th>
          <th>Email</th>
          <th>Endereço</th>
          <th>Agencia</th>
          <th>Colaborador</th>
          <th>Data de Inclusão</th>
        </tr>
        {prospeccoes && prospeccoes.length > 0 ? (
          prospeccoes.map(prospeccao => (
            <tr>
              <td width='12%'>
                {prospeccao.nome}

              </td>
              <td width='7%'>
                {maskTelefone(prospeccao.telePrincipal)}
                {prospeccao.teleOpcional &&
                  `     ${maskTelefone(prospeccao.teleOpcional)}`}
              </td>
              <td width='12%'>
                {prospeccao.atividade}
              </td>
              <td width='6%'>
                {prospeccao.cpf && `${maskCPF(prospeccao.cpf)}`}
              </td>
              <td width='12%'>
                {prospeccao.email}
              </td>
              <td width='10%'>
                {prospeccao.endereco}
              </td>
              <td width='8%'>
                {prospeccao.agencia}
              </td>
              <td width='15%'>
                {prospeccao.colaborador}
              </td>
              <td width='10%'>
                {prospeccao.dtInclusao
                  && format(zonedTimeToUtc(parseISO(prospeccao.dtInclusao.toString()), 'America/Sao_Paulo'), 'dd/MM/yyyy HH:mm')}
              </td>

            </tr>
          ))
        ) : !listaFiltradaVazia ? (
          <LoadingDiv>
            <ReactLoading
              type={'spin'}
              color={'black'}
              height={'2%'}
              width={'2%'}
            />
          </LoadingDiv>
        ) : (
          <p>Não há resultados na filtragem.</p>
        )}
      </table>
    </Container>
  );
};

export default TableProspeccao;
