export interface ICampaign {
  campanhaId: number;
  nome: string;
  situacao: string;
  descricao: string;
  dtInicio: string;
  dtFim: string;
  mosaicoUrl: string;
  bannerUrl: string;
  produtoId: string;
  modalidade: string;
  sms: boolean;
  email: boolean;
  colabCriador: number;
  colabSubmeteu: number;
  status: string;
  topoUrl: string;
  taxa: number;
  tac: number;
  dtAtualizacao: string;
  nomeColabCriador: string;
  nomeColabSubmeteu: string;
  descricaoEnvio: string;
}

export interface IRecordLine {
  cpf: string;
  nome: string;
  isFalha: boolean;
  mensagensFalhaValidacao: string[];
}

export interface IEditCampaign {
  name: string;
  situation: string;
  start_date: string;
  end_date: string;
  last_update: string;
  created_at: string;
}

export interface IRootState {
  campaign: ICampaignsObg;
}

export interface INotificationState {
  notifications: INotificationObject;
}

export interface INotificationObject {
  notificacao: INotification[];
  loading: boolean;
  erro: boolean;
  isLogged: boolean;
  user: { profileObj?: IProfileObj };
  colabId: string;
  loginError: string;
}

export interface INotification {
  id: number;
  titulo: string;
  texto: string;
  nomeColab: string;
  dataEnvioN: string;
  dataEnvio: Date;
}

export interface ICampaignsObg {
  campaigns: ICampaign[];
  loading: boolean;
  isLogged: boolean;
  user: { profileObj?: IProfileObj };
  colabId: string;
  loginError: string;
}

export interface IProfileObj {
  email: string;
  familyName: string;
  givenName: string;
  googleId: string;
  imageUrl: string;
  name: string;
}

export interface ICampaignImportHistory {
  idCampanha: number;
  dtImportacao: string;
  registrosAceitos: number;
  registrosReimportados: number;
  registrosRejeitados: number;
  colaborador: string;
  url_csv: string;
}

export interface IUploadImage {
  imageUrl: string;
}

export interface IProspeccao{
  id:number,
  nome: string,
  telePrincipal: string,
  teleOpcional: string,
  cnae: string,
  atividade:string,
  cpf?: string,
  email?: string,
  endereco?: string,
  colabId: number,
  colaborador: string,
  agenciaId: number,
  agencia: string,
  dtInclusao: Date
}

export interface IUnidades{
  id:string,
  nome:string
}


export interface IColaboradores{
  id:number,
  nome:string
}


export const maskTelefone = (telefone: string): string => {
  if (telefone.length > 10) {
    const parte1 = telefone.slice(2, 7)
    const parte2 = telefone.slice(7)
    return `(${telefone.substring(0, 2)}) ${parte1}.${parte2}`
  }
  const parte1 = telefone.slice(2, 6)
  const parte2 = telefone.slice(6)

  return `(${telefone.substring(0, 2)}) ${parte1}.${parte2}`
}
export const maskCPF = (cpf: string): string => {
  if (cpf.length === 11) {
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, 11)}`
  }

  return cpf
}